import { callApi, callDynamicApi, callDynamicServerApi } from '../utils/apiCaller';
import axios from 'axios';
import { get, find } from 'lodash'
import { toast } from 'react-toastify';
import { WEB_URL } from '../utils/constants';
import {
  APP_DYNAMICS_RESOURCE,
  DYNAMICS_CLIENT_ID,
  DYNAMICS_CLIENT_SECRET,
  DYNAMICS_GRANT_TYPE
} from "../utils/constants";
export const USER_LOGIN = 'USER_LOGIN';
export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const SET_INITIAL_AUTH_NEW = 'SET_INITIAL_AUTH_NEW';
export const SUCCESS_CHECK_EMAIL = 'SUCCESS_CHECK_EMAIL';
export const ERROR_CHECK_EMAIL = 'ERROR_CHECK_EMAIL';
export const SUCCESS_AGENCY_CODE = 'SUCCESS_AGENCY_CODE';
export const ERROR_AGENCY_CODE = 'ERROR_AGENCY_CODE';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';
export const UPDATE_DYNAMIC_SUCCESS = 'UPDATE_DYNAMIC_SUCCESS';
export const UPDATE_DYNAMIC_ERROR = 'UPDATE_DYNAMIC_ERROR';
export const SAVE_SYSTEM_USER_ID = "SAVE_SYSTEM_USER_ID";
export const SAVE_SINGUP_SYSTEM_USER_ID = "SAVE_SINGUP_SYSTEM_USER_ID";
export const GET_AGENCY_CLASSIFICATIONS = "GET_AGENCY_CLASSIFICATIONS";
export const SAVE_BUSINESS_UNIT = "SAVE_BUSINESS_UNIT";


/*
  set initial auth  (ON LOGOUT)
*/
export const set_initial_auth = () => {
  let payload = true;
  return (dispatch) => {
    return Promise.all([dispatch({ type: SET_INITIAL_AUTH_NEW, payload })]).then(() => '');
  };
};

/*
 set Initial Auth
*/
export const setInitialAuth_ = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const setInitialAuth = (payload) => {
  return (dispatch) => dispatch(setInitialAuth_(payload));
};

/*
 login User
*/
export const loginUser_ = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    callApi('auth-login/', 'POST', data).then(async (response) => {
      if (response && response?.status === 201) {
        toast.success('Check yor mail to reset password');
        return false;        
      }

      if (response && response.code !== 400 && response.code !== 500 && !response.data.is_volunteer) {
        toast.error('You can not login with agency.');
        return false;
      }      
      else {
        if (response && response.code !== 400 && response.code !== 500) {
          await getDynamicUserOnLogin(dispatch, response);
        }
      }

    })
  };
};

export const getDynamicUserOnLogin = (dispatch, userData) => {

  callDynamicApi(`${userData.data.is_volunteer ? "contacts" : "accounts"}( ${userData.data.guid} )${userData.data.is_volunteer ? '?$expand=parentcustomerid_account($select=cr676_agency_code,cr676_location,cr676_full_access_permission,name)' : ''}`, 'GET', false).then((response) => {
    if (response.data.parentcustomerid_account && (!response.data.parentcustomerid_account.cr676_agency_code || response.data.parentcustomerid_account.cr676_agency_code == null)) {
      toast.error('Agency code is missing');
      return false;
    } else if (response.data.cr676_loginenabled === false) {
      toast.error('You are not authorized to login');
      return false;
    } else {

      if (response?.data?._owningbusinessunit_value)
        getDynamicUserBusinessUnit(dispatch, response.data._owningbusinessunit_value)

      if (response?.data?.parentcustomerid_account?.cr676_agency_code)       //getAgency account details
        getAgencyAccountDetails(dispatch, response.data.parentcustomerid_account.cr676_agency_code)

      dispatch(loginUser_(userData));
      return dispatch({
        type: 'STORE_DYNAMICS_USER_DETAILS',
        payload: response.data,
        userData: userData.data
      });

    }
    // return dispatch(loginUser_(newObj))
  });
};


export const getDynamicUserBusinessUnit = (dispatch, businessUnitId) => {

  callDynamicApi(`businessunits(${businessUnitId})`, 'GET', false).then((response) => {
    if (response?.data)
      return dispatch({
        type: 'STORE_DYNAMICS_USER_BUSINESS_DETAILS',
        payload: response.data,
      });
  });
}

export const getAgencyAccountDetails = (dispatch, agency_code) => {

  if (agency_code)
    callDynamicApi(`accounts?$filter=cr676_agency_code eq '${agency_code}' &$select=name, cr676_agency_code, cr676_location &$expand=owningbusinessunit`, 'GET').then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (response.data)
          return dispatch({
            type: 'STORE_AGENCY_ACCOUNT_DETAILS',
            payload: response.data,
          });
      }
    });
}

export const getDynamicUser = (dispatch, userData) => {
  callDynamicApi(`${userData.is_volunteer ? "contacts" : "accounts"}( ${userData.guid} )${userData.is_volunteer ? '?$expand=parentcustomerid_account($select=cr676_agency_code,cr676_location,cr676_full_access_permission,name)' : ''}`, 'GET', false).then((response) => {
    return dispatch({
      type: 'STORE_DYNAMICS_USER_DETAILS',
      payload: response.data,
      userData: userData
    });


    // return dispatch(loginUser_(newObj))
  });
};


export const saveSystemUserId_ = (payload) => {
  return {
    type: SAVE_SYSTEM_USER_ID,
    payload,
  };
};

export const saveSignupSystemUserId_ = (payload) => {
  return {
    type: SAVE_SINGUP_SYSTEM_USER_ID,
    payload,
  };
};

export const saveBusinessUnit_ = (payload) => {
  return {
    type: SAVE_BUSINESS_UNIT,
    payload
  }
}

export const systemUserId = (location, signUp = false) => {
  return (dispatch) => {
    callDynamicApi(`businessunits?$filter=name eq '${location}'`, 'GET', false).then((response) => {
      if (response.data) {
        const bussinessId = response.data.value[0].businessunitid;
        callDynamicApi(`systemusers?$filter=_businessunitid_value eq ${bussinessId} and title eq 'Executive Director' &$orderby=createdon desc`, 'GET', false).then((res) => {
          if (res.data) {

            if (Array.isArray(res.data.value)) {
              let systemuser = find(res.data.value, ['isdisabled', false])    //getting enabled owner
              if (!systemuser)
                systemuser = find(res.data.value, ['isdisabled', true])        //getting disable ownerddddddddddd

              const systemuserid = systemuser?.systemuserid
              
              // const systemuserid = res.data.value[0].systemuserid;
              if (signUp) {
                dispatch(saveSignupSystemUserId_(systemuserid));
              } else {
                dispatch(saveSystemUserId_(systemuserid));
              }

            }
          }
        });

        dispatch(saveBusinessUnit_(response.data))
      }
    });

  }
}

export const fetchDynamicUser = (userData, dynamic) => {
  return (dispatch) => {
    callDynamicApi(userData.is_volunteer ? "contacts(" + dynamic.contactid + ")?$expand=parentcustomerid_account($select=cr676_agency_code,cr676_location,cr676_full_access_permission)" : "accounts(" + userData.guid + ")", 'GET', false).then((response) => {
      return dispatch({
        type: 'STORE_DYNAMICS_USER_DETAILS',
        payload: response.data,
        userData: userData
      });
    });
  };
};

export const getLocation = () => {
  return (dispatch) => {
    callApi('list-cities/', 'GET').then((response) => {
      if (response && response.code === 200) {
        return dispatch({ type: GET_LOCATION_SUCCESS, response })
      } else {
        return dispatch({ type: GET_LOCATION_ERROR, response })
      }
    });
  };
};

/*
 login To
*/
export const loginToken_ = (payload) => {
  return {
    type: LOGIN_TOKEN,
    payload,
  };
};

export const loginToken = (dispatch, data, userData) => {
  callDynamicApi('41dce816-24cd-4d36-a93b-ca75247413e2/oauth2/token', 'POST', data, true)
    .then((response) => {
      if (response) {
        return dispatch(loginToken_(response.data));
      }
    }).then((res) => {
      getDynamicUser(dispatch, userData);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};



export const dynamicLoginToken = (data) => {
  return (dispatch) => {
    callDynamicApi('41dce816-24cd-4d36-a93b-ca75247413e2/oauth2/token', 'POST', data, true)
      .then((response) => {
        if (response) {
          return dispatch(loginToken_(response.data));
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};


export const fetchUser = (data) => {
  return (dispatch) => {
    callApi('get-user/', 'GET', data).then(async (response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        await getDynamicUser(dispatch, response.data);
      }
    });
  };
};

/*
SignUp user
 */


export const signUp = (data, isPrimaryContact, isReceiveEventEmail, systemUserId, businessUnitNumber) => {
  let volunteers = [];
  let userData = data;
  if (isPrimaryContact === false) {
    volunteers.push({
      "email": data.emailaddress1,
      "name": data.cr676_signingauthority,
      "web_url": WEB_URL,
      "password": data.evtech_password
    })
  }

  if (isPrimaryContact) {
    volunteers.push({
      "email": data.cr676_key_ticket_contact__email,
      "name": data.cr676_key_ticket_contact_name,
      "web_url": WEB_URL,
      "password": data.evtech_password
    })
  } else {
    volunteers.push({
      "email": data.cr676_key_ticket_contact__email,
      "name": data.cr676_key_ticket_contact_name,
      "web_url": WEB_URL,
    })
  }

  JSON.parse(data.cr676_alternate_tickets_collection).map((iteam) => {
    if (iteam.cr676_alternate_ticket_contact_email !== "") {
      volunteers.push(
        {
          "email": iteam.cr676_alternate_ticket_contact_email,
          "name": iteam.cr676_alternate_ticket_contact_name,
          "web_url": WEB_URL,
        }
      )
    }
  })

  let params = {
    // email: data.emailaddress1,
    // password: data.evtech_password,
    //  agency_code: data.cr676_agency_code,
    // location: data.cr676_location,
    volunteers: volunteers,
    // name: data.name,
  };

  return (dispatch) => {
    callApi('create-user/', 'POST', params)
      .then((response) => {
        return response;
      })
      .then((res) => {
        if (res.code == 201) {
          dynamicSignUp(data, res.data, userData, dispatch, isPrimaryContact, isReceiveEventEmail, systemUserId, businessUnitNumber);
        }
      });
  };
};

export const checkEmail = (data) => {
  let params = {
    email: data.emailaddress1
  }
  return (dispatch) => {
    callApi('email-check/', 'POST', params)
      .then((response) => {
        if (response.code === 200) {
          dispatch({ type: SUCCESS_CHECK_EMAIL, response })
        } else {
          dispatch({ type: ERROR_CHECK_EMAIL, response })
        }
      })
  }
}

export const checkAgencyCode = (data) => {
  let params = {
    agency_code: data.cr676_agency_code
  }

  return (dispatch) => {
    callApi('agency-code-check/', 'POST', params)
      .then((response) => {
        if (response.code === 200) {
          dispatch({ type: SUCCESS_AGENCY_CODE, response })
        } else {
          dispatch({ type: ERROR_AGENCY_CODE, response })
        }
      })
  }
}

const callContactsApi = async (name, jobtitle, telephone, mobilephone, emailaddress, userData, userList, isReceiveEventEmail, middlename, lastname, password, extraDetail, businessUnitNumber) => {
  let data;

  if (typeof isReceiveEventEmail === 'boolean') {
    data = {
      "cr676_receive_event_email": isReceiveEventEmail,
      "fullname": name + ' ' + middlename + ' ' + lastname,
      "firstname": name,
      "middlename": middlename,
      "lastname": lastname,
      "jobtitle": jobtitle,
      "telephone1": telephone,
      "mobilephone": mobilephone,
      "emailaddress1": emailaddress,
      "cr676_password": password,
      "ownerid@odata.bind": extraDetail["ownerid@odata.bind"]
    }
  } else {
    data = {
      "fullname": name + ' ' + middlename + ' ' + lastname,
      "firstname": name,
      "middlename": middlename,
      "lastname": lastname,
      "jobtitle": jobtitle,
      "telephone1": telephone,
      "mobilephone": mobilephone,
      "emailaddress1": emailaddress,
      "cr676_password": password,
      "ownerid@odata.bind": extraDetail["ownerid@odata.bind"]
    }
  }
  return callDynamicApi('contacts', 'POST', data).then((response) => {
    if (response.status == 204) {
      if (response.headers['odata-entityid']) {
        let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
        // console.log('volunteers>>>>', userData.volunteers);
        // console.log('1 userList.emailaddress1>>>>', userList.emailaddress1, userData);
        callApi('update-user/' + userData.volunteers[userData.volunteers.findIndex(item => item.email.toLowerCase() === userList.emailaddress1.toLowerCase())].id + '/', 'PUT', { guid: guid, business_unit: extraDetail?.address1_city, main_phone_number: businessUnitNumber ? businessUnitNumber : 'N/A' })
        // callApi('update-user/' + userData.id + '/', 'PUT', {guid: guid})
        return guid
      }
    }
  })
}

const callPrimaryContactsApi = async (userList, userData, data, businessUnitNumber) => {
  return callDynamicApi('contacts', 'POST', {
    "fullname": userList.cr676_key_ticket_contact_name + ' ' + userList.primary_middlename + ' ' + userList.primary_lastname,
    "firstname": userList.cr676_key_ticket_contact_name,
    "middlename": userList.primary_middlename,
    "lastname": userList.primary_lastname,
    "jobtitle": userList.cr676_key_ticket_contact_position,
    "telephone1": userList.cr676_key_ticket_contact_office_number,
    "mobilephone": userList.cr676_key_ticket_contact_cell_number,
    "emailaddress1": userList.cr676_key_ticket_contact__email,
    "adx_identity_newpassword": userList.cr676_key_ticket_contact__password,
    "ownerid@odata.bind": data["ownerid@odata.bind"]
  }).then((response) => {
    if (response.status == 204) {
      if (response.headers['odata-entityid']) {
        let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
        // console.log('2 guiddd>>>', guid, userData);

        callApi('update-user/' + userData.volunteers[userData.volunteers.findIndex(item => item.email.toLowerCase() === userList.cr676_key_ticket_contact__email.toLowerCase())].id + '/', 'PUT', { guid: guid, business_unit: data?.address1_city, main_phone_number: businessUnitNumber ? businessUnitNumber : 'N/A' })
        return guid;
      }
    }
  })
}

// email: data.emailaddress1,
// password: data.evtech_password,
// agency_code: data.cr676_agency_code,
// location: data.cr676_location,
// volunteers:volunteers,
// name: data.name,

const dynamicSignUp = async (data, userData, userList, dispatch, isPrimaryContact, isReceiveEventEmail, systemUserId, businessUnitNumber) => {
  // console.log('manisha>>>>>>>', data);
  let primarycontactid = "";
  let evtech_executivedirectorid = "";

  if (systemUserId) {
    data["ownerid@odata.bind"] = `systemusers(${systemUserId})`
  }


  if (isPrimaryContact) {
    primarycontactid = await callContactsApi(data.cr676_signingauthority, '', data.cr676_cellnumber, data.cr676_sa_phonenumber, data.emailaddress1, userData, userList, isReceiveEventEmail, data.middlename, data.lastname, data.evtech_password, data, businessUnitNumber)
    //evtech_executivedirectorid = await callContactsApi(data.cr676_key_ticket_contact_name, data.cr676_key_ticket_contact_position, data.cr676_key_ticket_contact_office_number,
    //data.cr676_key_ticket_contact_cell_number,data.cr676_key_ticket_contact__email, userData, userList, '',data.primary_middlename, data.primary_lastname)
    data['primarycontactid@odata.bind'] = `contacts(${primarycontactid})`
    data['evtech_executivedirectorid@odata.bind'] = `contacts(${primarycontactid})`
  } else {
    evtech_executivedirectorid = await callContactsApi(data.cr676_signingauthority, '', data.cr676_cellnumber, data.cr676_sa_phonenumber, data.emailaddress1, userData, userList, isReceiveEventEmail, data.middlename, data.lastname, data.evtech_password, data, businessUnitNumber)
    primarycontactid = await callPrimaryContactsApi(userList, userData, data, businessUnitNumber)
    data['primarycontactid@odata.bind'] = `contacts(${primarycontactid})`
    data['evtech_executivedirectorid@odata.bind'] = `contacts(${evtech_executivedirectorid})`
  }
  let primary_lastname = data.primary_lastname;
  let primary_middlename = data.primary_middlename;

  data['telephone1'] = data['extension'] + data['telephone1'];
  delete data['middlename'];
  delete data['lastname'];
  delete data['primary_middlename'];
  delete data['primary_lastname'];
  delete data['confirm_password'];
  delete data['cr676_alternate_tickets_collection1'];
  delete data['extension'];
  delete data['cr676_key_ticket_contact__password'];
  delete data['cr676_key_ticket_contact__confirm_password'];
  // console.log('ddddd', systemUserId);

  // return false;
  await callDynamicApi('accounts', 'POST', data).then((response) => {
    if (response.status == 204) {
      if (response.headers['odata-entityid']) {
        let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
        updateUser(guid, userData, userList, dispatch, isPrimaryContact, primary_lastname, primary_middlename, primarycontactid, evtech_executivedirectorid, data, businessUnitNumber);
      }
    }
    dispatch({ type: SUCCESS_CHECK_EMAIL, response: {} })
  });


};

export const getAgencyClassifications = () => {
  // return false;
  return (dispatch) => callDynamicApi('EntityDefinitions(70816501-edb9-4740-a16c-6a5efbc05d84)/Attributes(3b398e4f-e670-e811-a957-000d3af3b751)/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$select=LogicalName&$expand=OptionSet', 'GET')
    .then((response) => {
      if (response.status == 200 && get(response.data, 'OptionSet.Options') && Array.isArray(response.data.OptionSet.Options)) {

        const filterClassifications = response.data.OptionSet.Options.map(value => {
          return { id: value.Value, name: value.Label?.UserLocalizedLabel?.Label }
        })
        return dispatch({ type: GET_AGENCY_CLASSIFICATIONS, payload: filterClassifications })
      }
    });
}

export const updateDynamicUser = (userData, dynamic, data) => {
  // console.log('sss', userData)
  data.fullname = data.firstname + ' ' + data.middlename + ' ' + data.lastname;
  // console.log('sssssss', userData.is_volunteer ? 'contacts(' + dynamic.contactid + ')' : 'accounts(' + userData.guid + ')')
  return (dispatch) => {
    callDynamicApi(userData.is_volunteer ? 'contacts(' + dynamic.contactid + ')' : 'accounts(' + userData.guid + ')', 'PATCH', data, false).then((response) => {
      if (response.status === 204) {
        return dispatch({ type: UPDATE_DYNAMIC_SUCCESS, payload: { data: data, response: response } })
      } else {
        return dispatch({ type: UPDATE_DYNAMIC_ERROR, response })
      }
    });
  }
};

const updateUser = (guid, userData, userList, dispatch, isPrimaryContact, primary_lastname, primary_middlename, primarycontactid, evtech_executivedirectorid, data, businessUnitNumber) => {
  // console.log('businessUnitNumber', businessUnitNumber)
  let params = {
    guid: guid,
  };
  if (isPrimaryContact === false) {
    callDynamicApi(`contacts(${evtech_executivedirectorid})`, 'PATCH', {
      "parentcustomerid_account@odata.bind": `accounts(${guid})`
    })

    /* callDynamicApi('contacts', 'POST', {
    "fullname":userList.cr676_key_ticket_contact_name + ' ' + primary_middlename + ' ' + primary_lastname ,
     "firstname": userList.cr676_key_ticket_contact_name,
     "middlename": userList.primary_middlename,
     "lastname": userList.primary_lastname,
       "jobtitle" : userList.cr676_key_ticket_contact_position,
       "telephone1" : userList.cr676_key_ticket_contact_office_number,
       "mobilephone" : userList.cr676_key_ticket_contact_cell_number,
       "emailaddress1" : userList.cr676_key_ticket_contact__email,
       "adx_identity_newpassword" : userList.cr676_key_ticket_contact__password,
       "parentcustomerid_account@odata.bind":`accounts(${guid})`
   }).then((response) => {
     if (response.status == 204) {
       if (response.headers['odata-entityid']) {
         let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
         callApi('update-user/' + userData.volunteers[userData.volunteers.findIndex(item => item.email === userList.cr676_key_ticket_contact__email)].id + '/', 'PUT', {guid: guid})
       }
     }
   }) */

  }
  callDynamicApi(`contacts(${primarycontactid})`, 'PATCH', {
    "parentcustomerid_account@odata.bind": `accounts(${guid})`
  })
  //callApi('update-user/' + userData.id + '/', 'PUT', params);

  const alternateTicketArray = JSON.parse(userList.cr676_alternate_tickets_collection);
  const lastIndex = alternateTicketArray.length - 1;
  JSON.parse(userList.cr676_alternate_tickets_collection).map((iteam, i) => {
    // console.log('lindex', lastIndex, i);
    if (iteam.cr676_alternate_ticket_contact_email !== "") {
      callDynamicApi('contacts', 'POST', {
        "fullname": iteam.cr676_alternate_ticket_contact_name + ' ' + iteam.middlename + ' ' + iteam.lastname,
        "firstname": iteam.cr676_alternate_ticket_contact_name,
        "middlename": iteam.middlename,
        "lastname": iteam.lastname,
        "jobtitle": iteam.cr676_alternate_ticket_contact_position,
        "telephone1": iteam.cr676_alternate_ticket_contact_phone_number,
        "mobilephone": iteam.cr676_alternate_ticket_contact_cell_number,
        "emailaddress1": iteam.cr676_alternate_ticket_contact_email,
        "adx_identity_newpassword": iteam.cr676_alternate_ticket_contact_password,
        "parentcustomerid_account@odata.bind": `accounts(${guid})`,
        "ownerid@odata.bind": data["ownerid@odata.bind"]
      }).then((response) => {
        if (response.status == 204) {
          if (response.headers['odata-entityid']) {
            let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
            // console.log('3', userData)
            let res = callApi('update-user/' + userData.volunteers[userData.volunteers.findIndex(item => item.email.toLowerCase() === iteam.cr676_alternate_ticket_contact_email.toLowerCase())].id + '/', 'PUT', { guid: guid, business_unit: data?.address1_city, main_phone_number: businessUnitNumber ? businessUnitNumber : 'N/A' })
            if (i === lastIndex) {
              return dispatch({
                type: 'UPDATE_USER_AFTER_DYNAMICS',
                payload: res,
              });

            }
          }
        }
      })
    } else {
      return dispatch({
        type: 'UPDATE_USER_AFTER_DYNAMICS',
        payload: true,
      });
    }
  })

};

export const resetData = () => {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_USER_AFTER_DYNAMICS',
      payload: null,
    });
  }

}




export const forgetPassword = (data) => {
  let params = {
    email: data.email,
    web_url: WEB_URL,
  };
  return callApi('forget-password/', 'POST', params)
    .then((response) => {
      if (response.data != null) {
        toast.success('Password Reset Link has been sent to the Email Id');
      }
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const resetPassword = (data, history) => {
  let params = {
    password: data.password,
    token: data.token,
  };
  callApi('reset-password/', 'POST', params)
    .then((response) => {
      if (response.code == "200") {
        toast.success('Password has been reset successfully');
        history.push('/');
      }
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const updatePassword = (data, dynamic) => {
  callDynamicApi(`contacts(${dynamic.contactid})`, 'PATCH', { 'cr676_password': data.password }, false)
    .then((response) => {
      // console.log('respoins', response);
      if (response) {
        return true;
      }
    })
};

export const changePasswordFn = (data, history, dynamic) => {
  return (dispatch) => {
    let params = {
      old_password: data.old_password,
      password: data.password,
    };
    callApi('update-password/', 'POST', params)
      .then((response) => {
        // console.log(response);
        updatePassword(data, dynamic)
        if (response.code == "200") {

          toast.success('Password has been changed successfully');
          history.push('/');
        }
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

};