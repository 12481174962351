import moment from 'moment';
import { get } from 'lodash'
import { callDynamicApi } from '../utils/apiCaller';
import { DYNAMICS_API_URL } from "../utils/constants";
export const GET_BAR_GRAPH_DATA = 'GET_BAR_GRAPH_DATA';
export const GET_PIE_CHART_DATA = 'GET_PIE_CHART_DATA';
export const GET_LINE_GRAPH_DATA = 'GET_LINE_GRAPH_DATA';
export const GET_DATA = 'GET_DATA';
export const GET_AGENCY_DASHBOARD_DATA = 'GET_AGENCY_DASHBOARD_DATA';
export const GET_CLIENT_FILTER_DATA = 'GET_CLIENT_FILTER_DATA';
export const GET_AGENCY_GRAPH_DATA = 'GET_AGENCY_GRAPH_DATA';
export const GET_CLIENT_LINE_GRAPH_DATA = 'GET_CLIENT_LINE_GRAPH_DATA';
export const GET_CLIENT_PROCESSED_GRAPH_DATA = 'GET_CLIENT_PROCESSED_GRAPH_DATA';
export const GET_CLIENT_ALLOCATED_GRAPH_DATA = 'GET_CLIENT_ALLOCATED_GRAPH_DATA';
export const GET_AGENCY_LINE_GRAPH_DATA = 'GET_AGENCY_LINE_GRAPH_DATA';
export const GET_AGENCY_PIE_GRAPH_DATA = 'GET_AGENCY_PIE_GRAPH_DATA';
export const SAVE_EXPAND_MENU_STATUS = 'SAVE_EXPAND_MENU_STATUS';

const startYearDate = moment().startOf('year').format('YYYY-MM-DD');
const endYearDate = moment().endOf('year').format('YYYY-MM-DD');
let baseUrl = `${DYNAMICS_API_URL}`;

export const getBarGraphData_ = (payload) => {
	return {
		type: GET_BAR_GRAPH_DATA,
		payload
	}
}

export const getBarGraphData = (agencyCode, eventId) => {
	return (dispatch) => {
		const qs = window.unescape(`/evtech_ticketrequests?$orderby=createdon desc &$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and _evtech_agencyid_value eq null`)
		callDynamicApi(qs, 'GET').then(
			(response => {
				if (response && response.code !== 400 && response !== 500) {
					return dispatch(getBarGraphData_(response.data))
				}
			})
		)
	}
}

export const getPieChartData_ = (payload) => {
	return {
		type: GET_PIE_CHART_DATA,
		payload
	}
}

export const getPieChartData = (agencyCode, eventId) => {
	return (dispatch) => {
		const qs = window.unescape(`/evtech_ticketrequests?$orderby=createdon desc &$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and _evtech_agencyid_value eq null`)
		callDynamicApi(qs, 'GET').then(
			(response => {
				if (response && response.code !== 400 && response !== 500) {
					return dispatch(getPieChartData_(response.data))
				}
			})
		)
	}
}

export const getLineGraphData_ = (payload) => {
	return {
		type: GET_LINE_GRAPH_DATA,
		payload
	}
}

export const getLineGraphData = (agencyCode, eventId) => {
	return (dispatch) => {
		const qs = window.unescape(`/evtech_ticketrequests?$orderby=createdon desc &$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and _evtech_agencyid_value eq null`)
		callDynamicApi(qs, 'GET').then(
			(response => {
				if (response && response.code !== 400 && response !== 500) {
					return dispatch(getLineGraphData_(response.data))
				}
			})
		)
	}
}

export const getData_ = (payload) => {
	return {
		type: GET_DATA,
		payload,
	}
}

export const getData = (agencyCode) => {
	/*  `--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and evtech_requeststatus eq 807710000&$apply=aggregate(evtech_totaltickets with sum as client_requested) HTTP/1.1
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and evtech_requeststatus eq 807710000&$apply=aggregate(evtech_totaltickets with sum as client_requested) HTTP/1.1
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and (evtech_requeststatus eq 476940000 or evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004 )&$apply=aggregate(evtech_totaltickets with sum as client_processed_req,evtech_numberofchaperones with sum as client_proc_req_total_adult,evtech_numberofchildren with sum as client_proc_req_total_children) HTTP/1.1
	Accept: application/json
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004)&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket,evtech_numberofchaperones with sum as client_alloc_total_adult,evtech_numberofchildren with sum as client_alloc_total_children)  HTTP/1.1
	Accept: application/json
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 807710002&$apply=aggregate(evtech_totaltickets with sum as client_total_accepted) HTTP/1.1
	Accept: application/json
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003&$apply=aggregate(evtech_totaltickets with sum as client_total_denied) HTTP/1.1
	Accept: application/json
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq 'game_agency-1588230612435' and _evtech_agencyid_value eq null &$apply=aggregate(evtech_totalticketvalue with sum as client_total_net_value) HTTP/1.1
	Accept: application/json
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and  createdon ge '${startYearDate}T00:00:00Z' and createdon le '${endYearDate}T23:59:59Z'&$apply=aggregate(evtech_totalticketvalue with sum as client_total_value_this_year) HTTP/1.1
	Accept: application/json
	
	--batch_B
	Content-Type: application/http
	Content-Transfer-Encoding:binary
	
	GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}'  and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004)&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket,evtech_numberofchaperones with sum as client_alloc_total_adult,evtech_numberofchildren with sum as client_alloc_total_children,evtech_totalticketvalue with sum as client_alloc_price) HTTP/1.1
	Accept: application/json
	
	--batch_B--` */

	const body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null&$count=true&$select=evtech_ticketrequestid HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and evtech_requeststatus ne 807710000&$count=true&$select=evtech_ticketrequestid HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null &$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested,evtech_actualchaperones with sum as client_req_total_adult,evtech_actualchildren with sum as client_req_total_children) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 &$apply=aggregate(cr676_total_allocated with sum as client_processed_req, cr676_adult_allocate with sum as client_proc_req_total_adult, cr676_children_allocate with sum as client_proc_req_total_children) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 &$apply=aggregate(evtech_totalticketvalue with sum as client_total_net_value) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004  and  createdon ge '2020-01-01T00:00:00Z' and createdon le '2020-12-31T23:59:59Z'&$apply=aggregate(evtech_totalticketvalue with sum as client_total_value_this_year) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and evtech_requeststatus eq 476940004 &$apply=aggregate(evtech_totaltickets with sum as client_processed_req,evtech_numberofchaperones with sum as client_proc_req_total_adult,evtech_numberofchildren with sum as client_proc_req_total_children, evtech_totalticketvalue with sum as client_proc_price) HTTP/1.1
Accept: application/json

--batch_B--`

	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					const counts = getCountsFromBatchResponse(body);
					const data = values.concat(counts);
					return dispatch(getData_(data))
				}
			})
	}
}

export const getClientFilterData_ = (payload) => {
	return {
		type: GET_CLIENT_FILTER_DATA,
		payload,
	}
}

export const getClientFilterData = (agencyCode, agencyGuid, startDate = '', endDate = '') => {
	let body;
	if (startDate && endDate) {
		body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${startDate}T00:00:00Z' and createdon le '${endDate}T23:59:59Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested,evtech_actualchaperones with sum as client_req_total_adult,evtech_actualchildren with sum as client_req_total_children) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${startDate}T00:00:00Z' and createdon le '${endDate}T23:59:59Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${startDate}T00:00:00Z' and createdon le '${endDate}T23:59:59Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${startDate}T00:00:00Z' and createdon le '${endDate}T23:59:59Z'&$apply=aggregate(cr676_total_allocated with sum as client_processed_req,cr676_adult_allocate with sum as client_proc_req_total_adult,cr676_children_allocate with sum as client_proc_req_total_children) HTTP/1.1

Accept: application/json

--batch_B--`
	} else {
		body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null &$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested,evtech_actualchaperones with sum as client_req_total_adult,evtech_actualchildren with sum as client_req_total_children) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 &$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 &$apply=aggregate(cr676_total_allocated with sum as client_processed_req,cr676_adult_allocate with sum as client_proc_req_total_adult,cr676_children_allocate with sum as client_proc_req_total_children) HTTP/1.1
Accept: application/json

--batch_B--`
	}

	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					return dispatch(getClientFilterData_(values))
				}
			})
	}
}

const getValuesFromBatchResponse = (batchResString) => {
	//   const valueRegex = /"value":\s*\[([^\]]+)\]/gm;
	const valueRegex = /"value":\s*\[([^\]]+)\]|\s*\[()\]/gm;
	let values = [];
	let match;
	do {
		match = valueRegex.exec(batchResString);
		try {
			const value = (match[1] == undefined) ? JSON.parse('{}') : JSON.parse(match[1]);
			console.log('values', value)
			values = values.concat(value);
		} catch (err) {
			console.log('errr', err)

		}
	} while (match)
	return values;
}

export const getAgencyData_ = (payload) => {
	return {
		type: GET_AGENCY_DASHBOARD_DATA,
		payload,
	}
}

export const saveExpandMenuStatus = (payload) => {
	return {
		type: SAVE_EXPAND_MENU_STATUS,
		payload,
	}
}

export const getAgencyData = (agencyGuid) => {
	const body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq ${agencyGuid} &$count=true&$select=evtech_ticketrequestid HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq ${agencyGuid} and evtech_requeststatus eq 807710002 &$count=true&$select=evtech_ticketrequestid HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json


--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total,evtech_numberofchaperones with sum as agency_req_total_adult,evtech_numberofchildren with sum as agency_req_total_children) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json


--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and (evtech_requeststatus eq 807710002 or evtech_requeststatus eq 476940002) &$apply=aggregate(evtech_ticketsassigned with sum as agency_recvd_total,evtech_numberofchaperones with sum as agency_recvd_total_adult,evtech_numberofchildren with sum as agency_recvd_total_children,evtech_totalticketvalue with sum as agency_recvd_total_value) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq  '${agencyGuid}'&$apply=aggregate(evtech_totalticketvalue with sum as agency_total_net_value) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq  '${agencyGuid}' and createdon ge '${startYearDate}T00:00:00Z' and createdon le '${endYearDate}T23:59:59Z'&$apply=aggregate(evtech_totalticketvalue with sum as agency_total_value_this_year) HTTP/1.1
Accept: application/json

--batch_B--`

	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					const counts = getCountsFromBatchResponse(body);
					const data = values.concat(counts);
					console.log('agency data>>>>>', data);
					return dispatch(getAgencyData_(data))
				}
			})
	}
}


export const getAgencyFilterData_ = (payload) => {
	return {
		type: GET_AGENCY_GRAPH_DATA,
		payload,
	}
}
export const getAgencyFilterData = (agencyGuid, startDate = '', endDate = '') => {
	let body;
	if (startDate && endDate) {
		body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${startDate}T00:00:00Z' and createdon le '${endDate}T23:59:59Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${startDate}T00:00:00Z' and createdon le '${endDate}T23:59:59Z'&$apply=aggregate(evtech_ticketsassigned with sum as agency_recvd_total) HTTP/1.1
Accept: application/json

--batch_B--`

	} else {
		body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' &$apply=aggregate(evtech_totaltickets with sum as agency_req_total) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 &$apply=aggregate(evtech_ticketsassigned with sum as agency_recvd_total) HTTP/1.1
Accept: application/json

--batch_B--`
	}

	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					return dispatch(getAgencyFilterData_(values));
				}
			})
	}
}

export const getAgencyLineGraphData_ = (payload) => {
	return {
		type: GET_AGENCY_LINE_GRAPH_DATA,
		payload,
	}
}

export const getAgencyLineGraphData = (agencyGuid, year) => {

	let body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_january) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_february) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_march) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_april) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_may) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_june) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_july) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_august) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_september) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_october) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_november) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(evtech_totaltickets with sum as agency_req_total_december) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_january) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_february) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_march) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_april) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_may) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_june) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_july) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_august) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_september) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_october) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_november) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=_evtech_agencyid_value eq '${agencyGuid}'and evtech_requeststatus eq 807710002 and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(evtech_quanityassigned with sum as agency_recvd_total_december) HTTP/1.1
Accept: application/json


--batch_B--`;

	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					let jan = {};
					let feb = {};
					let march = {};
					let april = {};
					let may = {};
					let june = {};
					let july = {};
					let aug = {};
					let sep = {};
					let oct = {};
					let nov = {};
					let dec = {};
					jan['name'] = 'Jan';
					feb['name'] = 'Feb';
					march['name'] = 'Mar';;
					april['name'] = 'April';
					may['name'] = 'May';
					june['name'] = 'June';
					july['name'] = 'July';
					aug['name'] = 'Aug';
					sep['name'] = 'Sep';
					oct['name'] = 'Oct';
					nov['name'] = 'Nov';
					dec['name'] = 'Dec';

					values.map((item, index) => {
						switch (index) {
							case 0:
								jan['Requested'] = item.agency_req_total_january;
							case 1:
								feb['Requested'] = item.agency_req_total_february;
							case 2:
								march['Requested'] = item.agency_req_total_march;
							case 3:
								april['Requested'] = item.agency_req_total_april;
							case 4:
								may['Requested'] = item.agency_req_total_may;
							case 5:
								june['Requested'] = item.agency_req_total_june;
							case 6:
								july['Requested'] = item.agency_req_total_july;
							case 7:
								aug['Requested'] = item.agency_req_total_august;
							case 8:
								sep['Requested'] = item.agency_req_total_september;
							case 9:
								oct['Requested'] = item.agency_req_total_october;
							case 10:
								nov['Requested'] = item.agency_req_total_november;
							case 11:
								dec['Requested'] = item.agency_req_total_december;
							case 12:
								jan['Received'] = item.agency_recvd_total_january;
							case 13:
								feb['Received'] = item.agency_recvd_total_february;
							case 14:
								march['Received'] = item.agency_recvd_total_march;
							case 15:
								april['Received'] = item.agency_recvd_total_april;
							case 16:
								may['Received'] = item.agency_recvd_total_may;
							case 17:
								june['Received'] = item.agency_recvd_total_june;
							case 18:
								july['Received'] = item.agency_recvd_total_july;
							case 19:
								aug['Received'] = item.agency_recvd_total_august;
							case 20:
								sep['Received'] = item.agency_recvd_total_september;
							case 21:
								oct['Received'] = item.agency_recvd_total_october;
							case 22:
								nov['Received'] = item.agency_recvd_total_november;
							case 23:
								dec['Received'] = item.agency_recvd_total_december;
						}
					});
					let finalArray = [];
					let result = insert(finalArray, jan, feb, march, april, may, june, july, aug, sep, oct, nov, dec);
					finalArray.push(result);
					return dispatch(getAgencyLineGraphData_(finalArray));
				}
			})
	}

}

function insert(arr, ...items) {
	arr.push(...items);
}

export const getClientLineGraphData_ = (payload) => {
	return {
		type: GET_CLIENT_LINE_GRAPH_DATA,
		payload,
	}
}

export const getClientReqLineGraphData = (agencyCode, agencyGuid, year) => {
	let body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_january,evtech_actualchaperones with sum as client_req_total_adult_january,evtech_actualchildren with sum as client_req_total_children_january) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_februrary,evtech_actualchaperones with sum as client_req_total_adult_februrary,evtech_actualchildren with sum as client_req_total_children_februrary) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_march,evtech_actualchaperones with sum as client_req_total_adult_march,evtech_actualchildren with sum as client_req_total_children_march) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_april,evtech_actualchaperones with sum as client_req_total_adult_april,evtech_actualchildren with sum as client_req_total_children_april) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_may,evtech_actualchaperones with sum as client_req_total_adult_may,evtech_actualchildren with sum as client_req_total_children_may) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_june,evtech_actualchaperones with sum as client_req_total_adult_june,evtech_actualchildren with sum as client_req_total_children_june) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_july,evtech_actualchaperones with sum as client_req_total_adult_july,evtech_actualchildren with sum as client_req_total_children_july) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_august,evtech_actualchaperones with sum as client_req_total_adult_august,evtech_actualchildren with sum as client_req_total_children_august) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_september,evtech_actualchaperones with sum as client_req_total_adult_september,evtech_actualchildren with sum as client_req_total_children_september) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_october,evtech_actualchaperones with sum as client_req_total_adult_october,evtech_actualchildren with sum as client_req_total_children_october) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_november,evtech_actualchaperones with sum as client_req_total_adult_november,evtech_actualchildren with sum as client_req_total_children_november) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_agencyid eq null and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(evtech_actualtotaltickets with sum as client_total_requested_december,evtech_actualchaperones with sum as client_req_total_adult_december,evtech_actualchildren with sum as client_req_total_children_december) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_january) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_february) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_march) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_april) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_may) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_june) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_july) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_august) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_september) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_october) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_november) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(evtech_totaltickets with sum as total_agency_tickets_december) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_january) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_february) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_march) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_april) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_may) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_june) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_july) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_august) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_september) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_october) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_november) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq '${agencyGuid}' and evtech_requeststatus eq 807710002 and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(evtech_ticketsassigned with sum as total_tickets_received_december) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_january) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_feburary) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_march) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_april) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_may) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_june) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_july) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_august) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_september) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_october) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_november) HTTP/1.1

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_agencyid_value eq null and evtech_requeststatus eq 476940004 and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(cr676_total_allocated with sum as shared_total_tickets_december) HTTP/1.1


--batch_B--`


	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					console.log('ss', values);
					let jan = {};
					let feb = {};
					let march = {};
					let april = {};
					let may = {};
					let june = {};
					let july = {};
					let aug = {};
					let sep = {};
					let oct = {};
					let nov = {};
					let dec = {};
					jan['name'] = 'Jan';
					feb['name'] = 'Feb';
					march['name'] = 'Mar';;
					april['name'] = 'April';
					may['name'] = 'May';
					june['name'] = 'June';
					july['name'] = 'July';
					aug['name'] = 'Aug';
					sep['name'] = 'Sep';
					oct['name'] = 'Oct';
					nov['name'] = 'Nov';
					dec['name'] = 'Dec';
					values.map((item, index) => {
						switch (index) {
							case 0:
								jan['Requested'] = item.client_total_requested_january;
							case 1:
								feb['Requested'] = item.client_total_requested_februrary;
							case 2:
								march['Requested'] = item.client_total_requested_march;
							case 3:
								april['Requested'] = item.client_total_requested_april;
							case 4:
								may['Requested'] = item.client_total_requested_may;
							case 5:
								june['Requested'] = item.client_total_requested_june;
							case 6:
								july['Requested'] = item.client_total_requested_july;
							case 7:
								aug['Requested'] = item.client_total_requested_august;
							case 8:
								sep['Requested'] = item.client_total_requested_september;
							case 9:
								oct['Requested'] = item.client_total_requested_october;
							case 10:
								nov['Requested'] = item.client_total_requested_november;
							case 11:
								dec['Requested'] = item.client_total_requested_december;
							case 12:
								jan['Processed'] = item.total_agency_tickets_january;
							case 13:
								feb['Processed'] = item.total_agency_tickets_february;
							case 14:
								march['Processed'] = item.total_agency_tickets_march;
							case 15:
								april['Processed'] = item.total_agency_tickets_april;
							case 16:
								may['Processed'] = item.total_agency_tickets_may;
							case 17:
								june['Processed'] = item.total_agency_tickets_june;
							case 18:
								july['Processed'] = item.total_agency_tickets_july;
							case 19:
								aug['Processed'] = item.total_agency_tickets_august;
							case 20:
								sep['Processed'] = item.total_agency_tickets_september;
							case 21:
								oct['Processed'] = item.total_agency_tickets_october;
							case 22:
								nov['Processed'] = item.total_agency_tickets_november;
							case 23:
								dec['Processed'] = item.total_agency_tickets_december;
							case 24:
								jan['Allocated'] = item.total_tickets_received_january;
							case 25:
								feb['Allocated'] = item.total_tickets_received_ticket_february;
							case 26:
								march['Allocated'] = item.total_tickets_received_march;
							case 27:
								april['Allocated'] = item.total_tickets_received_april;
							case 28:
								may['Allocated'] = item.total_tickets_received_may;
							case 29:
								june['Allocated'] = item.total_tickets_received_june;
							case 30:
								july['Allocated'] = item.total_tickets_received_july;
							case 31:
								aug['Allocated'] = item.total_tickets_received_august;
							case 32:
								sep['Allocated'] = item.total_tickets_received_september;
							case 33:
								oct['Allocated'] = item.total_tickets_received_october;
							case 34:
								nov['Allocated'] = item.total_tickets_received_november;
							case 35:
								dec['Allocated'] = item.total_tickets_received_december;
							case 36:
								jan['Shared'] = item.shared_total_tickets_january;
							case 37:
								feb['Shared'] = item.shared_total_tickets_feburary;
							case 38:
								march['Shared'] = item.shared_total_tickets_march;
							case 39:
								april['Shared'] = item.shared_total_tickets_april;
							case 40:
								may['Shared'] = item.shared_total_tickets_may;
							case 41:
								june['Shared'] = item.shared_total_tickets_june;
							case 42:
								july['Shared'] = item.shared_total_tickets_july;
							case 43:
								aug['Shared'] = item.shared_total_tickets_august;
							case 44:
								sep['Shared'] = item.shared_total_tickets_september;
							case 45:
								oct['Shared'] = item.shared_total_tickets_october;
							case 46:
								nov['Shared'] = item.shared_total_tickets_november;
							case 47:
								dec['Shared'] = item.shared_total_tickets_december;
						}
					});
					let finalArray = [];
					let result = insert(finalArray, jan, feb, march, april, may, june, july, aug, sep, oct, nov, dec);
					finalArray.push(result);
					return dispatch(getClientLineGraphData_(finalArray));
				}
			})
	}

}

export const getClientProcessedGraphData_ = (payload) => {
	return {
		type: GET_CLIENT_PROCESSED_GRAPH_DATA,
		payload,
	}
}
export const getClientProcessedLineGraphData = (agencyCode, year) => {
	let body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_january) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_february) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_march) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_april) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_may) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_june) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_july) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_august) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_september) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_october) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_november) HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and evtech_requeststatus eq 807710003 and _evtech_agencyid_value eq null and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T2:59:59Z'&$apply=aggregate(evtech_totaltickets with sum as client_total_denied_december) HTTP/1.1
Accept: application/json


--batch_B--`


	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					console.log('Processed line graph data', values);
					let data = {};
					data['name'] = 'Processed';
					values.map((item, index) => {
						switch (index) {
							case 0:
								data['Jan'] = item.client_processed_req_january;
							case 1:
								data['Feb'] = item.client_processed_req_februrary;
							case 2:
								data['March'] = item.client_processed_req_march;
							case 3:
								data['April'] = item.client_processed_req_april;
							case 4:
								data['May'] = item.client_processed_req_may;
							case 5:
								data['June'] = item.client_processed_req_june;
							case 6:
								data['July'] = item.client_processed_req_july;
							case 7:
								data['Aug'] = item.client_processed_req_august;
							case 8:
								data['Sep'] = item.client_processed_req_september;
							case 9:
								data['Oct'] = item.client_processed_req_october;
							case 10:
								data['Nov'] = item.client_processed_req_november;
							case 11:
								data['Dec'] = item.client_processed_req_december;
						}
					});
					//console.log('line graph data', data);
					return dispatch(getClientProcessedGraphData_(data));
				}
			})
	}

}

export const getClientAllocatedGraphData_ = (payload) => {
	return {
		type: GET_CLIENT_ALLOCATED_GRAPH_DATA,
		payload,
	}
}
export const getClientAllocatedLineGraphData = (agencyCode, year) => {
	let body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-01-01T00:00:00Z' and createdon le '${year}-02-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_january,evtech_numberofchaperones with sum as client_alloc_total_adult_january,evtech_numberofchildren with sum as client_alloc_total_children_january)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-02-01T00:00:00Z' and createdon le '${year}-03-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_february,evtech_numberofchaperones with sum as client_alloc_total_adult_february,evtech_numberofchildren with sum as client_alloc_total_children_february)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-03-01T00:00:00Z' and createdon le '${year}-04-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_march,evtech_numberofchaperones with sum as client_alloc_total_adult_march,evtech_numberofchildren with sum as client_alloc_total_children_march)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-04-01T00:00:00Z' and createdon le '${year}-05-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_april,evtech_numberofchaperones with sum as client_alloc_total_adult_april,evtech_numberofchildren with sum as client_alloc_total_children_april)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-05-01T00:00:00Z' and createdon le '${year}-06-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_may,evtech_numberofchaperones with sum as client_alloc_total_adult_may,evtech_numberofchildren with sum as client_alloc_total_children_may)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-06-01T00:00:00Z' and createdon le '${year}-07-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_june,evtech_numberofchaperones with sum as client_alloc_total_adult_june,evtech_numberofchildren with sum as client_alloc_total_children_june)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-07-01T00:00:00Z' and createdon le '${year}-08-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_july,evtech_numberofchaperones with sum as client_alloc_total_adult_july,evtech_numberofchildren with sum as client_alloc_total_children_july)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-08-01T00:00:00Z' and createdon le '${year}-09-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_august,evtech_numberofchaperones with sum as client_alloc_total_adult_august,evtech_numberofchildren with sum as client_alloc_total_children_august)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-09-01T00:00:00Z' and createdon le '${year}-10-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_september,evtech_numberofchaperones with sum as client_alloc_total_adult_september,evtech_numberofchildren with sum as client_alloc_total_children_september)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-10-01T00:00:00Z' and createdon le '${year}-11-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_october,evtech_numberofchaperones with sum as client_alloc_total_adult_october,evtech_numberofchildren with sum as client_alloc_total_children_october)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-11-01T00:00:00Z' and createdon le '${year}-12-01T00:00:00Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_november,evtech_numberofchaperones with sum as client_alloc_total_adult_november,evtech_numberofchildren with sum as client_alloc_total_childre_november)  HTTP/1.1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and _evtech_agencyid_value eq null and (evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and createdon ge '${year}-12-01T00:00:00Z' and createdon le '${year}-12-31T23:59:59Z'&$apply=aggregate(evtech_totaltickets with sum as client_alloc_total_ticket_december,evtech_numberofchaperones with sum as client_alloc_total_adult_december,evtech_numberofchildren with sum as client_alloc_total_children_december)  HTTP/1.1
Accept: application/json


--batch_B--`


	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {
					const body = response.data;
					const values = getValuesFromBatchResponse(body);
					console.log('Allocated line graph data', values);
					let data = {};
					data['name'] = 'Allocated';
					values.map((item, index) => {
						switch (index) {
							case 0:
								data['Jan'] = item.client_alloc_total_ticket_january;
							case 1:
								data['Feb'] = item.client_alloc_total_ticket_februrary;
							case 2:
								data['March'] = item.client_alloc_total_ticket_march;
							case 3:
								data['April'] = item.client_alloc_total_ticket_april;
							case 4:
								data['May'] = item.client_alloc_total_ticket_may;
							case 5:
								data['June'] = item.client_alloc_total_ticket_june;
							case 6:
								data['July'] = item.client_alloc_total_ticket_july;
							case 7:
								data['Aug'] = item.client_alloc_total_ticket_august;
							case 8:
								data['Sep'] = item.client_alloc_total_ticket_september;
							case 9:
								data['Oct'] = item.client_alloc_total_ticket_october;
							case 10:
								data['Nov'] = item.client_alloc_total_ticket_november;
							case 11:
								data['Dec'] = item.client_alloc_total_ticket_december;
						}
					});
					//console.log('line graph data', data);
					return dispatch(getClientAllocatedGraphData_(data));

				}
			})
	}

}

const getCountsFromBatchResponse = (batchResString) => {
	const valueRegex = /"@odata.count":([0-9]+)/gm;
	let values = [];
	let match;
	do {
		match = valueRegex.exec(batchResString);
		try {
			const value = JSON.parse(match[1]);
			values = values.concat(value);
		} catch (err) {
			// pass
		}
	} while (match)

	return values;
}

const getValuesArraysFromBatchResponse = (batchResString) => {
	const valueRegex = /"value":\s*\[([^\]]+)\]|\s*\[()\]/gm;
	let values = [];
	let match;
	do {
		match = valueRegex.exec(batchResString);
		console.log('match', match)
		try {
			let value;
			if (match && match[1]) {
				value = match[1]
				let regxBackSlashQuoteQuote = new RegExp(/\\""/g)       //removing backslash and double quoute from string
				value = value.replaceAll(regxBackSlashQuoteQuote, '"')
				let regxBackSlashQuote = new RegExp(/\\"/g)             //removing backslash and quote from string
				value = value.replaceAll(regxBackSlashQuote, '')
				let regxForwardSlash = new RegExp(/\//g)                //removing forward slash from string
				value = value.replaceAll(regxForwardSlash, '')
				value = JSON.parse("[" + value + "]");
			}
			// value = (match[1] == undefined) ? JSON.parse('{}') : JSON.parse(match[1]);
			if (value)
				values.push(value);
		} catch (err) {
			console.log('eerrr', err)
		}
	} while (match)
	return values;
}


export const getAgencyPieGraphData = (agencyGuid, eventCategories) => {
	// $filter=cr676_agency_code eq 'Honda_20-1606302663946' and evtech_eventid ne null &$expand=evtech_eventid($select=evtech_eventcategory; $filter= evtech_eventcategory eq 807710000) &$select=evtech_totaltickets 
	let body = ``
	for (let category of eventCategories) {
		body += `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyGuid}' and evtech_eventid ne null &$expand=evtech_eventid($select=evtech_eventcategory; $filter= evtech_eventcategory eq ${category.code}) &$select=evtech_totaltickets HTTP/1.1
Prefer: OData-MaxVersion=4.0
Accept: application/json


`
	}

	// &$select=evtech_totaltickets &$expand=evtech_eventid($filter= evtech_eventcategory eq ${category.code}) HTTP/1.1

	if (body && body !== ``)
		body += `--batch_B--`

	return (dispatch) => {
		callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
			.then((response) => {
				if (response && response.status !== 400 && response.status !== 500) {

					let values = getValuesArraysFromBatchResponse(response.data);
					values = values.map((val, ind) => val.reduce((accumulator, currentValue) => { return currentValue.evtech_eventid !== null ? accumulator + currentValue.evtech_totaltickets : accumulator + 0 }, 0))   //reducing array of arrays
					console.log('values', values)
					const totalTicketsAssigned = values.reduce((accumulator, currentValue) => accumulator + currentValue)

					const agencyPieGraphData = eventCategories.map((value, index) => {
						let ticketsPercentage = fixedDecimalPoint(((get(values, `[${index}]`, 0) / totalTicketsAssigned) * 100), 2)
						return { name: value.name, value: ticketsPercentage }
					})
					return dispatch(getClientAllocatedPieData_(agencyPieGraphData));

				}
			})
	}
}

export const fixedDecimalPoint = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);

export const getClientAllocatedPieData_ = (payload) => {
	return {
		type: GET_AGENCY_PIE_GRAPH_DATA,
		payload,
	}
} 