import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import FilterDropdown from './FilterDropdown';
var d = new Date();
var currentYear = d.getFullYear();
 const  filterGraphDataOptions = [
            { name:"filterDataValue" ,value: currentYear - 2, label: currentYear - 2 },
            { name:"filterDataValue" ,value: currentYear-1, label: currentYear -1  },
            { name:"filterDataValue" ,value: currentYear, label: currentYear }
        ];
	


export default class LineGraphChart extends React.Component {
    render(){
		 const {filterData, graphData, selectValue, showLegends } = this.props;
		 console.log('legends', graphData);
	 let filteredGraphData = graphData.filter(function (el) {
            return el != null;
        });
		
    return (
	 <div>
		 <div className="mb-3 ml-auto" style={{maxWidth: "200px"}}>
			<FilterDropdown
			filterGraphDataOptions = {filterGraphDataOptions}
			selectValue={selectValue}
				onChangeEvent={(event) => {
					console.log(event);
					filterData(event.value);
				}}
			/>
		</div>
		<div>
		{showLegends ? ( <LineChart width={600} height={300} data={filteredGraphData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
				<CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
				<Tooltip />
                <Legend />
                   <Line name="Client Request" type="monotone" dataKey="Requested" stroke="#007bff" />
                   <Line name="Agency Request" type="monotone" dataKey="Processed" stroke="#fd7e14" />
				   
				   <Line name="Experiences Received" type="monotone" dataKey="Allocated" stroke="#28a745" />
                   <Line name="Experiences Allocated" type="monotone" dataKey="Shared" stroke="#dc3545" />
		</LineChart> ): (
		<LineChart width={600} height={300} data={filteredGraphData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
				<CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
				<Tooltip />
                <Legend />
                   <Line name="Experiences Requested" type="monotone" dataKey="Requested" stroke="#8884d8" />
                   <Line name="Experiences Approved" type="monotone" dataKey="Received" stroke="#82ca9d" />
		</LineChart>
		)}
            <div>
                <Legend />
            </div>
        </div>
		
		{/* <div>
            <LineChart width={600} height={300} data={graphData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
				<CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
				<Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Jan" stroke="#8884d8" />
                <Line type="monotone" dataKey="Feb" stroke="#82ca9d" />
				<Line type="monotone" dataKey="March" stroke="#ff7f0e" />
                <Line type="monotone" dataKey="April" stroke="#2ca02c" />
				 <Line type="monotone" dataKey="May" stroke="#d62728" />
                <Line type="monotone" dataKey="June" stroke="#9467bd" />
				<Line type="monotone" dataKey="July" stroke="#8c564b" />
                <Line type="monotone" dataKey="Aug" stroke="#e377c2" />
				<Line type="monotone" dataKey="Sep" stroke="#7f7f7f" />
                <Line type="monotone" dataKey="Oct" stroke="#bcbd22" />
				<Line type="monotone" dataKey="Nov" stroke="#17becf" />
                <Line type="monotone" dataKey="Dec" stroke="#333333" />
            </LineChart>
            <div>
                <Legend />
            </div>
        </div> */}
		</div>
    );
}
}

