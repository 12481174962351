import React from "react";
import {Icon} from '../../UI/atoms/Icon';

export const ActionButtonsAgency = ({
  isModifying,
  isEditing,
  status,
  ticketRequestId,
  index,
  onModifyRow,
  onEditRow,
  onSaveAgencyRequest,
  onCancelAgencyRequest,
  onCancelChangeAgencyRequest
}) =>{
	 const isDisabled = (status === 'Accepted'  || status === 'Change Allocated' ) ? true : false ;
	return (
  <div className="action-buttons">
    {isModifying ? (
	  <>
      <button className="btn btn-primary"   disabled = {!isDisabled} onClick={() => onModifyRow(ticketRequestId)}>Change Request </button>
	  <button  className="btn btn-danger" disabled = {isDisabled} onClick={() => onCancelChangeAgencyRequest(ticketRequestId)}>Cancel</button>
	  </>
    ) : (
      <>
	  {isEditing ? (<button className="btn btn-primary" onClick={() => onSaveAgencyRequest(ticketRequestId)}>Save Changes</button>) : (
	  <>
	  <button className="btn btn-secondary" onClick={() => onEditRow(ticketRequestId)}>Edit</button>  
	  <button  className="btn btn-danger" onClick={() => onCancelAgencyRequest(ticketRequestId, index)}>Cancel</button>
	  </>
	  )}
     
      </>
    )}
  </div>
);
}
