/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp, checkEmail, checkAgencyCode, resetData, dynamicLoginToken, getAgencyClassifications } from '../../../actions/AuthActions';
import { getEventCategories } from "../../../actions/EventActions";
import { DefaultButton } from '../../UI/atoms/DefaultButton';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import SignUpStepOne from './SignUpStep1';
import SignUpStepTwo from './SignUpStep2';
import SignUpStepThree from './SignUpStep3';
import Steps from './steps';
import { toast } from 'react-toastify';
import { checkValid, checkValid3, validate, validateStep2, validateStep3, validateLocation, validateStep2CheckBoxes } from '../../../utils/validations';
import { APP_DYNAMICS_RESOURCE, DYNAMICS_CLIENT_ID, DYNAMICS_CLIENT_SECRET, DYNAMICS_GRANT_TYPE } from "../../../utils/constants";
import { get } from 'lodash'

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step1: true,
      step2: false,
      step3: false,
      isPrimaryContact: false,
      isReceiveEventEmail: false,
      email_exist_Data: {},
      agency_code_exist_Data: {},
      categoryList: [],
      signUp: {
        name: '',
        address1_line1: '',
        address1_city: '',
        emailaddress1: '',
        address1_postalcode: '',
        telephone1: '',
        extension: '',
        evtech_password: '',
        confirm_password: '',
        cr676_location: '',
        cr676_signingauthority: '',
        middlename: '',
        lastname: '',
        primary_middlename: '',
        primary_lastname: '',
        cr676_sa_phonenumber: '',
        cr676_cellnumber: '',
        cr676_agency_code: '',
        new_alignmentwithmission: '',
        new_agencybenefits: '',
        cr676_events_tickets_interests: '',
        cr676_eventfreeticketothersdescription: '',
        cr676_key_ticket_contact_name: '',
        cr676_key_ticket_contact_position: '',
        cr676_key_ticket_contact_office_number: '',
        cr676_key_ticket_contact_cell_number: '',
        cr676_key_ticket_contact__email: '',
        cr676_key_ticket_contact__password: '',
        cr676_key_ticket_contact__confirm_password: '',
        cr676_alternate_tickets_collection: '',

        cr676_alternate_tickets_collection1: [
          {
            lastname: '',
            middlename: '',
            cr676_alternate_ticket_contact_name: '',
            cr676_alternate_ticket_contact_position: '',
            cr676_alternate_ticket_contact_phone_number: '',
            cr676_alternate_ticket_contact_cell_number: '',
            cr676_alternate_ticket_contact_email: '',
            cr676_alternate_ticket_contact_password: '',
            cr676_alternate_ticket_contact_confirm_password: '',
          },
        ],

        cr676_children_serve_0_6: '',
        cr676_children_serve_7_12: '',
        cr676_children_serve_13_18: '',
        bsi_numberofchildren: '',

        cr676_short_tickets_tat: '',
        cr676_short_tickets_tat_description: '',

        cr676_pickup_tickets_from_hq: '',
        cr676_pickup_tickets_from_hq_description: '',
        new_liabilityinsurancelevel: '',

        bsi_agencyclassification: '',
        address1_stateorprovince: '',
        // bsi_demographicsaddictions: true,  not required
        new_pickuptickets: true,
        customertypecode: 1,
      },
      validationLocation: {
        locationV: false
      },
      validationStep1: {
        nameV: false,
        address1_line1V: false,
        address1_postalcodeV: false,
        telephone1V: null,
        cr676_signingauthorityV: false,
        lastnameV: false,
        emailV: false,
        passwordV: false,
        agencyV: false,
        agency_codeV: true,
        phone_numberV: null,
        cell_numberV: null,
        password_confirmationV: false,
      },
      validationStep2: {
        new_alignmentwithmissionV: false,
        new_agencybenefitsV: false,
        new_ticketsfromotherorganizationsV: false,
        pickup_ticketV: false,
        short_ticketV: false,
        liabilityV: false,
        children_serve_6V: null,
        children_serve_7V: null,
        children_serve_13V: null,
        interestsCheckBoxV: false
      },
      validationStep3: {
        ticketContactCellNumberV: null,
        ticketContactEmailV: null,
        // ticketContactpasswordconfirmationV: null,
        // ticketContactpasswordV: null,
        AltcontactPhoneNumberV: null,
        AltTicketContactCellNumberV: null,
        AltTicketContactEmailV: null,
        AllternativeValidation: []
      },
    };
  }

  xyz = () => {
    let props = this.props;
    const { signUp, isPrimaryContact, isReceiveEventEmail } = this.state;
    let businessUnitNumber = undefined
    if (get(this.props, 'businessUnit.value[0].address1_telephone1'))
      businessUnitNumber = get(this.props, 'businessUnit.value[0].address1_telephone1')
    else if (get(this.props, 'businessUnit.value[0].address1_telephone2'))
      businessUnitNumber = get(this.props, 'businessUnit.value[0].address1_telephone2')
    else if (get(this.props, 'businessUnit.value[0].address1_telephone3'))
      businessUnitNumber = get(this.props, 'businessUnit.value[0].address1_telephone3')

    props.signUpUserFn(signUp, isPrimaryContact, isReceiveEventEmail, props.systemUserId, businessUnitNumber);
  };

  componentDidMount() {

    // this.props.getAgencyClassifications()
    this.props.getEventCategoriesFn()

    this.props.dynamicLoginTokenFn({
      client_id: DYNAMICS_CLIENT_ID,
      grant_type: DYNAMICS_GRANT_TYPE,
      client_secret: DYNAMICS_CLIENT_SECRET,
      resource: APP_DYNAMICS_RESOURCE
    });

    if (this.props.user) {
      this.props.history.push('/agency/dashboard');
    }
  }



  componentWillReceiveProps(props) {
    if (props.update_success) {
      props.history.push('/');
      toast.success('User registration successfully! Please Login');
      this.setState({
        step1: true,
        step2: false,
        step3: false
      })
    }

    if (props.get_event_categories) {
      const data = [];
      props.get_event_categories.map((item) => {
        data.push(
          {
            name: item.account_key,
            label: item.name
          }
        )
        this.setState({
          signUp: {
            ...this.state.signUp,
            [item.account_key]: false
          }
        })
      })
      this.setState({
        categoryList: data
      })
    }
  }

  componentWillUnmount() {
    const { resetData } = this.props
    resetData()
  }

  checkInterest = (event) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      signUp: {
        ...prevState.signUp,
        [name]: checked ? true : false,
      },
      validationStep2: validateStep2CheckBoxes(prevState.validationStep2, name, checked, prevState.categoryList, { ...prevState.signUp, [name]: checked }),
    }));
  }
  onChangeEvent = (name, value, index, validateName) => {
    const {
      signUp,
      validationStep1,
      validationStep2,
      validationStep3,
      validationLocation,
      validationStep3: { AllternativeValidation },
      signUp: {
        evtech_password,
        confirm_password,
        new_liabilityinsurancelevel,
        cr676_alternate_tickets_collection,
        cr676_alternate_tickets_collection1,
        bsi_agencyclassification,
        cr676_pickup_tickets_from_hq,
        cr676_pickup_tickets_from_hq_description,
        cr676_short_tickets_tat,
        cr676_short_tickets_tat_description,
        cr676_children_serve_0_6,
        cr676_children_serve_7_12,
        cr676_children_serve_13_18,
        cr676_key_ticket_contact__password,
        cr676_key_ticket_contact__confirm_password,
        cr676_key_ticket_contact__email,
        emailaddress1
      },
    } = this.state;
    this.setState((prevState) => ({
      signUp: {
        // object that we want to update
        ...prevState.signUp, // keep all other key-value pairs
        [name]: value, // update the value of specific key
        cr676_short_tickets_tat_description:
          cr676_short_tickets_tat === 'false'
            ? ''
            : name === 'cr676_short_tickets_tat_description'
              ? value
              : cr676_short_tickets_tat_description,
        cr676_pickup_tickets_from_hq_description:
          cr676_pickup_tickets_from_hq === 'false'
            ? ''
            : name === 'cr676_pickup_tickets_from_hq_description'
              ? value
              : cr676_pickup_tickets_from_hq_description,
        bsi_numberofchildren:
          (name === 'cr676_children_serve_0_6'
            ? parseInt(value)
            : parseInt(cr676_children_serve_0_6)) +
          (name === 'cr676_children_serve_7_12'
            ? parseInt(value)
            : parseInt(cr676_children_serve_7_12)) +
          (name === 'cr676_children_serve_13_18'
            ? parseInt(value)
            : parseInt(cr676_children_serve_13_18)),
        cr676_alternate_tickets_collection: (name === "cr676_alternate_tickets_collection1") ? JSON.stringify(value) : (cr676_alternate_tickets_collection1.length == 1) ? JSON.stringify(cr676_alternate_tickets_collection1) : cr676_alternate_tickets_collection1,
        new_liabilityinsurancelevel: (name === "new_liabilityinsurancelevel") ? parseInt(value) : new_liabilityinsurancelevel,
        bsi_agencyclassification: (name === "bsi_agencyclassification") ? parseInt(value) : bsi_agencyclassification
      },
      validationLocation: validateLocation(validationLocation, name, value),
      validationStep1: validate(validationStep1, name, value, evtech_password, confirm_password),
      validationStep2: validateStep2(validationStep2, name, value),
      validationStep3: validateStep3(validationStep3, name, value, cr676_key_ticket_contact__password,

        cr676_key_ticket_contact__confirm_password, index, AllternativeValidation, validateName, cr676_key_ticket_contact__email, cr676_alternate_tickets_collection1, emailaddress1),
    }));

  };
  step1Continue = async (data) => {
    const { isPrimaryContact, signUp: { cr676_cellnumber, cr676_signingauthority, emailaddress1, cr676_sa_phonenumber, name, cr676_key_ticket_contact_name,
      cr676_key_ticket_contact_position, cr676_key_ticket_contact_office_number, cr676_key_ticket_contact_cell_number, cr676_key_ticket_contact__email, middlename, lastname, primary_lastname, primary_middlename } } = this.state;
    const emailAddress = this.state.signUp.emailaddress1;
    await this.setState((prevState) => ({
      signUp: {
        // object that we want to update
        ...prevState.signUp, // keep all other key-value pairs
        cr676_agency_code: emailAddress.substring(0, emailAddress.lastIndexOf("@")) + '-' + new Date().getTime(),
        primary_middlename: isPrimaryContact ? middlename : primary_middlename,
        primary_lastname: isPrimaryContact ? lastname : primary_lastname,
        cr676_key_ticket_contact_name: isPrimaryContact ? cr676_signingauthority : cr676_key_ticket_contact_name,
        cr676_key_ticket_contact_position: isPrimaryContact ? name : cr676_key_ticket_contact_position,
        cr676_key_ticket_contact_office_number: isPrimaryContact ? cr676_sa_phonenumber : cr676_key_ticket_contact_office_number,
        cr676_key_ticket_contact_cell_number: isPrimaryContact ? cr676_cellnumber : cr676_key_ticket_contact_cell_number,
        cr676_key_ticket_contact__email: isPrimaryContact ? emailaddress1 : cr676_key_ticket_contact__email
      }
    }));
    const { signUp } = this.state;
    await this.props.checkEmailFn(signUp);
    // await this.props.checkAgncyFn(signUp);

  }
  checkValidationLocation = () => {
    const { validationLocation } = this.state;
    const check = checkValid(validationLocation);
    if (check) {
      return true;
    }
    return false;
  };

  checkValidation = () => {
    const { validationStep1 } = this.state;
    const check = checkValid(validationStep1);
    if (check) {
      return true;
    }
    return false;
  };

  checkValidationStep2 = () => {
    const { validationStep2, categoryList } = this.state;

    const check = checkValid(validationStep2);
    if (check) {
      return true;
    }
    return false;
  };

  checkValidationStep3 = () => {
    const { validationStep3 } = this.state;
    const check = checkValid3(validationStep3);
    if (check) {

      return true;
    }
    return false;
  };

  componentDidUpdate(prevProp) {
    const { email_exist_Data } = this.state
    const { email_exist, dynamicToken, agency_classifications } = this.props

    console.log('didupate', email_exist_Data, email_exist)
    if (prevProp.dynamicToken?.access_token && !agency_classifications)
      this.props.getAgencyClassifications()
    else if (prevProp.dynamicToken?.access_token && Array.isArray(agency_classifications) && agency_classifications.length < 1)
      this.props.getAgencyClassifications()

    if (email_exist_Data !== email_exist) {
      if (email_exist && email_exist.code === 200) {
        this.setState({
          step1: false,
          step2: true,
        })
      }

      this.setState({
        email_exist_Data: email_exist,
      })
    }
  }

  render() {
    const {
      step1,
      step2,
      step3,
      signUp,
      categoryList,
      validationLocation,
      validationStep1,
      validationStep2,
      validationStep3,
      isPrimaryContact,
      isReceiveEventEmail,
      signUp: { middlename, lastname, cr676_signingauthority, name, cr676_sa_phonenumber, cr676_cellnumber, emailaddress1 }
    } = this.state;
    return (
      <div className="Signup">
        <Container>
          <h3>Agency Application Form</h3>
          <Steps steps={this.state} checkValidation={this.checkValidation()} checkValidationStep2={this.checkValidationStep2()} step1Click={() => {
            this.setState({
              step1: true,
              step2: false,
              step3: false,

            })
          }
          }
            step2Click={() => {
              this.setState({
                step1: false,
                step2: true,
                step3: false,

              })
            }
            }
            step3Click={() => {
              this.setState({
                step1: false,
                step2: false,
                step3: true,
              })
            }
            }

          />

          <div className="Signup-form">
            <Form>
              {step1 && (
                <SignUpStepOne
                  formData={signUp}
                  onChangeEvent={(name, value) => {
                    this.onChangeEvent(name, value);
                  }}
                  step1={() => { this.step1Continue() }}
                  validationStep1={validationStep1}
                  validationLocation={validationLocation}
                  checkValidation={this.checkValidation()}
                  checkValidationLocation={this.checkValidationLocation()}
                  checkChange={() => {
                    this.setState({
                      isPrimaryContact: !isPrimaryContact
                    },
                      () => {
                        this.setState({
                          signUp: {
                            ...this.state.signUp,
                            primary_middlename: this.state.isPrimaryContact ? middlename : '',
                            primary_lastname: this.state.isPrimaryContact ? lastname : '',
                            cr676_key_ticket_contact_name: this.state.isPrimaryContact ? cr676_signingauthority : '',
                            cr676_key_ticket_contact_position: this.state.isPrimaryContact ? name : '',
                            cr676_key_ticket_contact_office_number: this.state.isPrimaryContact ? cr676_sa_phonenumber : '',
                            cr676_key_ticket_contact_cell_number: this.state.isPrimaryContact ? cr676_cellnumber : '',
                            cr676_key_ticket_contact__email: this.state.isPrimaryContact ? emailaddress1 : '',
                          }
                        })

                      }
                    )
                  }}
                  isPrimaryContact={isPrimaryContact}
                  checkReceiveChange={() => {
                    this.setState({
                      isReceiveEventEmail: !isReceiveEventEmail
                    })
                  }}
                  isReceiveEventEmail={isReceiveEventEmail}
                />
              )}
              {step2 && (
                <SignUpStepTwo
                  formData={signUp}
                  onChangeEvent={(name, value) => {
                    this.onChangeEvent(name, value);
                  }}
                  step2={(data, step1, step3) => {
                    this.setState({
                      step1: step1,
                      step2: false,
                      step3: step3,
                      step2Object: data,
                    });
                  }}
                  categoryList={categoryList}
                  onChangeCheckEvent={(event) => {
                    this.checkInterest(event);
                  }}
                  validationStep2={validationStep2}
                  checkValidationStep2={this.checkValidationStep2()}
                />
              )}
              {/* <SignUpStepThree
                        formData={signUp}
                        onChangeEvent={(name, value, index, validateName) => {
                          this.onChangeEvent(name, value, index, validateName);
                        }}
                        finalStep={() => {
                                            this.xyz();
                                          }}
                //         finalStep={(data, step2, step3) => {
                //           this.setState(
                //               {
								// step1: false,  
                //                 step2: step2,
                //                 step3: step3,
                //                 step3Object: data,
                //               },
                //               () => {
                //                 if (!step2) {
                //                   this.xyz();
                //                 }
                //               },
                //           );
                //         }}
                        validationStep3={validationStep3}
                        checkValidationStep3={this.checkValidationStep3()}
                        isPrimaryContact={isPrimaryContact}
                    /> */}

              {step3 && (
                <SignUpStepThree
                  formData={signUp}
                  onChangeEvent={(name, value, index, validateName) => {
                    this.onChangeEvent(name, value, index, validateName);
                  }}
                  finalStep={(data, step2, step3) => {
                    this.setState(
                      {
                        step1: false,
                        step2: step2,
                        step3: step3,
                        step3Object: data,
                      },
                      () => {
                        if (!step2) {
                          this.xyz();
                        }
                      },
                    );
                  }}
                  validationStep3={validationStep3}
                  checkValidationStep3={this.checkValidationStep3()}
                  isPrimaryContact={isPrimaryContact}
                />
              )}
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    systemUserId: state.authReducer.systemUserId,
    update_success: state.authReducer.update_success,
    email_exist: state.authReducer.email_exist,
    agency_code_exist: state.authReducer.agency_code_exist,
    get_event_categories: state.eventReducer.get_event_categories,
    dynamicToken: state.authReducer.dynamic,
    agency_classifications: state.authReducer.agency_classifications,
    businessUnit: state.authReducer.businessUnit
  };
};

const mapDispatch = (dispatch) => ({
  signUpUserFn: (user, isPrimaryContact, isReceiveEventEmail, systemUserId, businessUnitNumber) => dispatch(signUp(user, isPrimaryContact, isReceiveEventEmail, systemUserId, businessUnitNumber)),
  getEventCategoriesFn: () => dispatch(getEventCategories()),
  resetData: () => dispatch(resetData()),
  checkEmailFn: user => dispatch(checkEmail(user)),
  checkAgncyFn: user => dispatch(checkAgencyCode(user)),
  getAgencyClassifications: () => dispatch(getAgencyClassifications()),
  dynamicLoginTokenFn: (data) => dispatch(dynamicLoginToken(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Signup));
