import React from 'react';
import { PieChart, Pie, Cell, Legend, XAxis, Label } from 'recharts';
import FilterDropdown from './FilterDropdown';
import { Container, Row, Col } from 'reactstrap';
import COLORS from '../../../../assets/colors'

var d = new Date();
var currentYear = d.getFullYear();
const filterGraphDataOptions = [
    { name: "filterDataValue", value: 'all', label: 'All' },
    { name: "filterDataValue", value: currentYear - 2, label: currentYear - 2 },
    { name: "filterDataValue", value: currentYear - 1, label: currentYear - 1 },
    { name: "filterDataValue", value: currentYear, label: currentYear }
];



export default class PieChartGraph extends React.Component {

    renderCustomLabel = item => (
        <text
            fill={item.fill}
            x={item.x}
            y={item.y}
            stroke='none'
            alignmentBaseline='middle'
            className='recharts-text recharts-pie-label-text'
            textAnchor='end'
        >
            <tspan x={item.x} textAnchor={item.textAnchor} dy='0em'>{item.value + '%'}</tspan>
        </text>
    )

    spliceSlice(str, index, count, add) {
        // We cannot pass negative indexes directly to the 2nd slicing operation.
        if (index < 0) {
            index = str.length + index;
            if (index < 0) {
                index = 0;
            }
        }
        console.log('return str', str.slice(0, index) + (add || "") + str.slice(index + count))
        return str.slice(0, index) + (add || "") + str.slice(index + count);
    }

    reverseString = str => {
        str += ''
        return str.split('').reverse().join('');
    }

    render() {
        const { filterData, filterGraphDataOptions, graphData, selectValue, showFilter } = this.props;
        const decendingSortedGraphData = graphData.sort((a, b) => b.value - a.value);
        const filteredGraphData = decendingSortedGraphData.filter(a => a.value > 0)

        return (
            <div>
                {
                    showFilter &&
                    <div className="mb-3 ml-auto" style={{ maxWidth: "200px" }}>
                        <FilterDropdown
                            filterGraphDataOptions={filterGraphDataOptions}
                            selectValue={selectValue}
                            onChangeEvent={(event) => {
                                filterData(event.value);
                            }} />
                    </div>
                }


                <Row>
                    <Col lg="9">
                        <div className="graph-outer">
                            <PieChart width={500} height={400}>
                                <Pie
                                    data={filteredGraphData}
                                    dataKey='value'
                                    nameKey='name'
                                    // cx={120}
                                    // cy={200}
                                    innerRadius={60}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    label={item => this.renderCustomLabel(item)}
                                    labelLine={true}
                                    isAnimationActive={false}
                                >
                                    {filteredGraphData.map((entry, index) => <Cell fill={COLORS[index].hex} />)}
                                </Pie>
                                {/* <XAxis dataKey="name">
                                    <Label value="Total Experiences Assigned" offset={0} position="insideBottom" />
                                </XAxis> */}
                            </PieChart>
                            <h5 className="text-center pl-3">Total Experiences Assigned</h5>
                        </div>
                    </Col>
                    {this.props.showLegends ? (
                        <Col lg="3" >
                            <ul className="graph-labels pie-graph-labels">
                                <li className="blue"><span className="tile"></span> <span className="tile-name">Received</span></li>
                                <li className="orange"><span className="tile"></span> <span className="tile-name">Processed</span></li>
                                <li className="green"><span className="tile"></span> <span className="tile-name">Allocated</span></li>
                                <li className="red"><span className="tile"></span> <span className="tile-name">Accepted</span></li>
                                <li className="purple"><span className="tile"></span> <span className="tile-name">Denied</span></li>
                            </ul>
                        </Col>
                    ) : (
                            <Col lg="3">
                                <ul className="graph-labels pie-graph-labels">
                                    {
                                        decendingSortedGraphData.map((value, index) => {
                                            return <li className=""><span className="tile" style={{ backgroundColor: COLORS[index].hex }}></span> <span className="tile-name">{value.name}</span></li>
                                        })
                                    }
                                    {/* <li className="blue"><span className="tile"></span> <span className="tile-name">Requested</span></li> */}
                                    {/* <li className="orange"><span className="tile"></span> <span className="tile-name">Received</span></li> */}
                                </ul>
                            </Col>
                        )}
                </Row>

                <div>
                    <Legend />
                </div>
            </div>
        );
    }
}
